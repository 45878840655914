import {
  options,
  options_category,
  options_category_select,
} from "@Modules/Options/configs/constants";

import list_options from "./reducers";
import list_options_category from "./reducers/category";
import list_options_category_select from "./reducers/optionsSelect";

export default {
  [options]: list_options,
  [options_category]: list_options_category,
  [options_category_select]: list_options_category_select,
};
