import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "ReportCostPrice",
  dir: "ReportCostPrice",
  pathRoot: "report-cost-price",
  routes: [
    {
      url: "",
      component: "Page",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Báo cáo đối tác và giá vốn | 5 Phút Thuộc Bài",
        titleI18n: "ReportCostPrice:titleTab",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
};
