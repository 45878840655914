import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "SalesOrder",
  dir: "SalesOrder",
  pathRoot: "sales-order",
  routes: [
    {
      url: ":id",
      component: "Page/CreateEdit",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Đơn hàng ngoài hệ thống | 5 Phút Thuộc Bài",
        titleI18n: "SalesOrder:updateTab",
      },
    },
    {
      url: "",
      component: "Page",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Đơn hàng ngoài hệ thống | 5 Phút Thuộc Bài",
        titleI18n: "SalesOrder:titleTab",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
};
