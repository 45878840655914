import { put, call, takeEvery, takeLatest } from "redux-saga/effects";
import * as Actions from "../constants";
import { getUserSummary, getTopPoint, getReportTopPoint } from "../service";
import { RETCODE_SUCCESS, SUCCESS } from "@configs/contants";
/**
 * Fetch data saga
 * @returns {IterableIterator<*>}
 */
function* getUserSummarySaga({ payload }) {
  try {
    const res = yield call(getUserSummary, payload);
    const { data } = res;
    if (data.retCode === RETCODE_SUCCESS) {
      yield put({
        type: Actions.GET_USERS_SUMMARY_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: Actions.GET_USERS_SUMMARY_FAILURE,
        error: data.retText,
      });
    }
  } catch (e) {
    yield put({ type: Actions.GET_USERS_SUMMARY_FAILURE, error: e });
  }
}

function* getReportUserTopSaga({ payload }) {
  const { body, key } = payload;
  try {
    const res = yield call(getReportTopPoint, body);
    const { data } = res;
    if (data.retCode === RETCODE_SUCCESS) {
      yield put({
        type: Actions.GET_REPORT_TOP_USERS_SUCCESS,
        payload: { data: data.data, key },
      });
    } else {
      yield put({
        type: Actions.GET_REPORT_TOP_USERS_FAILURE,
        error: data.retText,
      });
    }
  } catch (e) {
    yield put({ type: Actions.GET_REPORT_TOP_USERS_FAILURE, error: e });
  }
}

function* getUserTopSaga({ payload }) {
  const { body, key } = payload;
  try {
    const res = yield call(getTopPoint, body);
    const { data } = res;
    if (data.retCode === RETCODE_SUCCESS) {
      yield put({
        type: Actions.GET_TOP_USERS_SUCCESS,
        payload: { data: data.data, key },
      });
    } else {
      yield put({
        type: Actions.GET_TOP_USERS_FAILURE,
        error: data.retText,
      });
    }
  } catch (e) {
    yield put({ type: Actions.GET_TOP_USERS_FAILURE, error: e });
  }
}

export default function* forgotSaga() {
  yield takeEvery(Actions.GET_USERS_SUMMARY_REQUEST, getUserSummarySaga);
  yield takeEvery(Actions.GET_REPORT_TOP_USERS_REQUEST, getReportUserTopSaga);
  yield takeEvery(Actions.GET_TOP_USERS_REQUEST, getUserTopSaga);
}
