import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "ConfigCardAmount",
  dir: "ConfigCardAmount",
  pathRoot: "config-card-amount",
  routes: [
    {
      url: "",
      component: "Page/Form",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Thiết lập tồn thẻ | 5 Phút Thuộc Bài - Đối tác Tâm Trí Lực",
        titleI18n: "Thiết lập tồn thẻ",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  roles: [
    "superadmin",
    "view_admin",
    "edit_admin",
    "chief_accountant",
    "accountant",
  ],
};
