import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "Admin",
  dir: "Admin",
  pathRoot: "admin",
  routes: [
    {
      url: ":id",
      component: "Page/CreateEdit",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Người dùng | 5 Phút Thuộc Bài - Đối tác Tâm Trí Lực",
        titleI18n: "Admin:updateTab",
      },
    },
    {
      url: "",
      component: "Page",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Người dùng | 5 Phút Thuộc Bài - Đối tác Tâm Trí Lực",
        titleI18n: "Admin:titleTab",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  roles: ["superadmin", "view_admin", "edit_admin"],
};
