import adminTable from "@store/adminTable/reducers";

import {
  list_user_wallet,
  user_transfer_history,
  user_change_balance,
} from "@Modules/ListUser/configs/constants";

export default adminTable(list_user_wallet);
export const transferHistory = adminTable(user_transfer_history);
export const changeBalance = adminTable(user_change_balance);
