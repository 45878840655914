export const GET_USERS_SUMMARY_REQUEST = "GET_USERS_SUMMARY_REQUEST";
export const GET_USERS_SUMMARY_SUCCESS = "GET_USERS_SUMMARY_SUCCESS";
export const GET_USERS_SUMMARY_FAILURE = "GET_USERS_SUMMARY_FAILURE";

export const GET_REPORT_TOP_USERS_REQUEST = "GET_REPORT_TOP_USERS_REQUEST";
export const GET_REPORT_TOP_USERS_SUCCESS = "GET_REPORT_TOP_USERS_SUCCESS";
export const GET_REPORT_TOP_USERS_FAILURE = "GET_REPORT_TOP_USERS_FAILURE";


export const GET_TOP_USERS_REQUEST = "GET_TOP_USERS_REQUEST";
export const GET_TOP_USERS_SUCCESS = "GET_TOP_USERS_SUCCESS";
export const GET_TOP_USERS_FAILURE = "GET_TOP_USERS_FAILURE";