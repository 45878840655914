export default {
  GET_USER_SUMMARY: "/Report/GetUsersSummary",
  GET_USER_BIRTHDAY: "/Report/GetUsersBirthday",
  EXPORT_USER_BIRTHDAY: "/Report/ExportUsersBirthday",
  GET_NEW_USERS: "/Report/GetNewUsers",
  EXPORT_NEW_USER: "/Report/ExportNewUser",
  GET_USER_WAS_INVITED: "/Report/GetUsersWasInvited",
  EXPORT_USER_WAS_INVITED: "/Report/ExportUsersWasInvited",
  GET_TOP_POINT: "/user/GetTopPoint",
  GET_TOP_REPORT_POINT: "/user/GetReportTopPoint",

  //Dash board:
  GET_DASHBOARD_REPORT: "/Report/dashboard",

  GET_AFFILIATE_USERS_REPORT: '/AffiliateUsersReport/dashboard',
};
