import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "CommisionTicketReply",
  dir: "CommisionTicketReply",
  pathRoot: "commission-ticket-reply",
  routes: [
    {
      url: "",
      component: "Page/index",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Đăng nhập | 5 Phút Thuộc Bài Tâm Trí Lực",
        titleI18n: "CommisionTicketReply:title",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
};
