import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "Department",
  dir: "Department",
  pathRoot: "department",
  routes: [
    {
      url: "history-department",
      component: "Page/HistoryDepartmentPage",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Duyệt hệ số phòng ban | 5 Phút Thuộc Bài - Đối tác Tâm Trí Lực",
        titleI18n: "Department:titleTab",
      },
    },
    {
      url: "approved-personel",
      component: "Page/DuyetHeSoNhanVien",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Duyệt hệ nhân viên | 5 Phút Thuộc Bài - Đối tác Tâm Trí Lực",
        titleI18n: "Department:titleTab",
      },
    },
    {
      url: "approve-department",
      component: "Page/ApproveDepartmentPage",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Duyệt hệ số phòng ban | 5 Phút Thuộc Bài - Đối tác Tâm Trí Lực",
        titleI18n: "Department:titleTab",
      },
    },
    {
      url: "",
      component: "Page/Form",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Phòng ban | 5 Phút Thuộc Bài - Đối tác Tâm Trí Lực",
        titleI18n: "Department:titleTab",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  roles: ["superadmin", "chief_accountant", "hr", "accountant"],
};
