import {
  config_user_group,
  list_user,
} from "@Modules/ConfigUserGroup/configs/constants";

import list_personnel from "./reducers";
import listUserReducer from "./reducers/listUser";

export default {
  [config_user_group]: list_personnel,
  [list_user]: listUserReducer,
};
