import { takeEvery, call, put, all, select } from "redux-saga/effects";

/* Actions */
import * as actions from "./actions";

/*Constants*/
import { RETCODE_SUCCESS } from "@configs/contants";

/*Reducers*/
import { get_action_table } from "./reducers";

/*Selectors*/
import { defaultValue } from "./selectors";

import { getCodeLanguage } from "../common/selectors";

export const getState = (state) => state;

function* get_indentity_table_data_worker({ payload }) {
  const {
    identity,
    api,
    current,
    pageSize,
    filter,
    treeMode,
    treeKey,
    dynamicKey,
  } = payload;
  const opts = {
    page: current,
    limit: pageSize,
    ...filter,
  };

  yield put({
    type: get_action_table(identity, actions.GET_IDENTITY_TABLE_DATA_REQUEST),
  }); // Trigger loading

  try {
    const codeLanguage = yield select(getCodeLanguage);
    const res = yield call(api, { ...opts, codeLanguage });

    const { data } = res;

    if (data.retCode === RETCODE_SUCCESS) {
      let payload = { list: [], pagination: {}, filters: {} };
      if (Array.isArray(data.data)) {
        payload = {
          list: data.data,
          pagination: defaultValue.pagination,
          filters: filter,
          treeMode,
          treeKey,
        };
      } else {
        const { listData, paging, extendsData, totalData, ...otherData } =
          data.data;

        payload = {
          list: listData,
          pagination: paging,
          filters: filter,
          treeMode,
          treeKey,
          extendsData,
          totalData,
          ...otherData,
        };
      }
      yield put({
        type: get_action_table(
          identity,
          actions.GET_IDENTITY_TABLE_DATA_SUCCESS,
        ),
        payload,
      });
    } else {
      yield put({
        type: get_action_table(
          identity,
          actions.GET_IDENTITY_TABLE_DATA_FAILURE,
        ),
      });
    }
  } catch (error) {
    console.error(error.response?.data);
    yield put({
      type: get_action_table(identity, actions.GET_IDENTITY_TABLE_DATA_FAILURE),
    });
  }
}

function* refresh_indentity_table_data_worker({ payload }) {
  const {
    identity,
    api,
    filter = {},
    treeMode = true,
    treeKey = "parentId",
  } = payload;

  const ds = yield select(getState);
  const codeLanguage = yield select(getCodeLanguage);
  const identitySelect = ds[identity];
  const { pagination = {}, filters = {} } = identitySelect;
  const filterRefresh = { ...filters, ...filter };
  const opts = {
    page: 1,
    limit: pagination.limitPage || 50,
    ...filterRefresh,
  };

  // Trigger loading
  yield put({
    type: get_action_table(
      identity,
      actions.REFRESH_IDENTITY_TABLE_DATA_REQUEST,
    ),
  });

  
  try {
    const res = yield call(api, { ...opts, codeLanguage });
    const { data } = res;
    if (data.retCode === RETCODE_SUCCESS) {
      let payload = { list: [], pagination: {}, filters: {} };
      if (Array.isArray(data.data)) {
        payload = {
          list: data.data,
          pagination: defaultValue.pagination,
          filters: filter,
        };
      } else {
        const { listData, paging, extendsData } = data.data;
        payload = {
          list: listData,
          pagination: paging,
          filters: filterRefresh,
          treeMode,
          treeKey,
          extendsData,
        };
      }

      yield put({
        type: get_action_table(
          identity,
          actions.GET_IDENTITY_TABLE_DATA_SUCCESS,
        ),
        payload,
        treeMode,
        treeKey,
      });
    } else {
      yield put({
        type: get_action_table(
          identity,
          actions.GET_IDENTITY_TABLE_DATA_FAILURE,
        ),
      });
    }
  } catch (error) {
    console.error(error.response?.data);
    yield put({
      type: get_action_table(
        identity,
        actions.REFRESH_IDENTITY_TABLE_DATA_FAILURE,
      ),
    });
  }
}

export function* get_indentity_table_data_watcher() {
  yield takeEvery(
    actions.GET_IDENTITY_TABLE_DATA_REQUEST,
    get_indentity_table_data_worker,
  );
}

export function* refresh_identity_table_data_watcher() {
  yield takeEvery(
    actions.REFRESH_IDENTITY_TABLE_DATA_REQUEST,
    refresh_indentity_table_data_worker,
  );
}

export default function* () {
  yield all([
    get_indentity_table_data_watcher(),
    refresh_identity_table_data_watcher(),
  ]);
}
