import {
  list_user_wallet,
  user_transfer_history,
  user_change_balance,
} from "@Modules/ListUser/configs/constants";

import list_user, { transferHistory, changeBalance } from "./reducers";

export default {
  [list_user_wallet]: list_user,
  [user_transfer_history]: transferHistory,
  [user_change_balance]: changeBalance,
};
