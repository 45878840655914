import storage from "redux-persist/lib/storage";
import * as Actions from "../constants";

const initState = {
  summary: { newUsersCount: 0, allUsersCount: 0, usersWasInvited: 0 },
  loading: false,
  error: "",
  top: { week: [], month: [], quarter: [], all: [] },
};

const DashboardReducer = (state = initState, action = {}) => {
  switch (action.type) {
    case Actions.GET_USERS_SUMMARY_REQUEST:
      return { ...state, loading: true };
    case Actions.GET_USERS_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        summary: action.payload,
      };
    case Actions.GET_USERS_SUMMARY_FAILURE:
      const { error } = action;
      return {
        ...state,
        loading: false,
        error,
        data: {},
      };
    case Actions.GET_TOP_USERS_REQUEST:
      return { ...state, loading: true };
    case Actions.GET_TOP_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        top: { ...state.top, ...{ [action.payload.key]: action.payload.data } },
      };
    case Actions.GET_TOP_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: {},
      };
    case Actions.GET_REPORT_TOP_USERS_REQUEST:
      return { ...state, loading: true };
    case Actions.GET_REPORT_TOP_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        top: { ...state.top, ...{ [action.payload.key]: action.payload.data } },
      };
    case Actions.GET_REPORT_TOP_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
        data: {},
      };
    default:
      return state;
  }
};

const persistConfig = {
  key: "Dashboard",
  storage,
  blacklist: ["loading", "error"],
};

export default DashboardReducer;
