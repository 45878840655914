import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "ConfigUserGroup",
  dir: "ConfigUserGroup",
  pathRoot: "config-user-group",
  routes: [
    {
      url: ":id",
      component: "Page/CreateEdit",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Đối tượng | 5 Phút Thuộc Bài - Đối tác Tâm Trí Lực",
        titleI18n: "ConfigUserGroup:updateTab",
      },
    },

    {
      url: "",
      component: "Page",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Đối tượng | 5 Phút Thuộc Bài - Đối tác Tâm Trí Lực",
        titleI18n: "ConfigUserGroup:titleTab",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  roles: ["superadmin", "hr", "chief_accountant"],
};
