import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "ReportPaymentOrder",
  dir: "ReportPaymentOrder",
  pathRoot: "",
  routes: [
    {
      url: "report-payment-order/detail/:id",
      component: "Page/Detail",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Báo cáo đề nghị thanh toán | 5 Phút Thuộc Bài",
        titleI18n: "ReportPaymentOrder:titleTab",
      },
    },
    {
      url: "report-payment-order",
      component: "Page",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Báo cáo đề nghị thanh toán | 5 Phút Thuộc Bài",
        titleI18n: "ReportPaymentOrder:titleTab",
      },
    },
  
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
};
