import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "InfomationManagement",
  dir: "InfomationManagement",
  pathRoot: "information-management",
  routes: [
    {
      url: "",
      component: "Page/index",
      layout: "VerticalLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "5 Phút Thuộc Bài - Đối tác Tâm Trí Lực",
        titleI18n: "InfomationManagement:title",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  roles: ["superadmin", "view_admin", "edit_admin"],
};
