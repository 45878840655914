import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "NLCommissions",
  dir: "NLCommissions",
  pathRoot: "nl_commission",
  routes: [
    {
      url: "",
      component: "Page/Form",
      meta: {
        authRoute: true,
      },
      props: {
        title:
          "Tùy chỉnh phí thanh toán bằng Ngân Lượng | 5 Phút Thuộc Bài - Đối tác Tâm Trí Lực",
        titleI18n: "PercentCommision:titleTab",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  roles: [
    "superadmin",
    "view_admin",
    "edit_admin",
    "accountant",
    "chief_accountant",
  ],
};
