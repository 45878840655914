import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "DateConfig",
  dir: "DateConfig",
  pathRoot: "date-config",
  routes: [
    {
      url: "",
      component: "Page",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Câu hỏi | 5 Phút Thuộc Bài",
        titleI18n: "DateConfig:titleTab",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  roles: ["superadmin", "view_admin", "edit_admin", 'accountant'],
};
