import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "Personnel",
  dir: "Personnel",
  pathRoot: "personnel",
  routes: [
    {
      url: "history/:id",
      component: "Page/HistoryUserTransfer.js",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Lịch sử người dùng | 5 Phút Thuộc Bài - Đối tác Tâm Trí Lực",
        titleI18n: "Personnel:updateTab",
      },
    },
    {
      url: "list/:id",
      component: "Page/ListViUserPage.js",
      meta: {
        authRoute: true,
      },
      props: {
        title:
          "Danh sách ví người dùng | 5 Phút Thuộc Bài - Đối tác Tâm Trí Lực",
        titleI18n: "Personnel:updateTab",
      },
    },
    // {
    //   url: "department/:id",
    //   component: "Page/ConfigPercentPage.js",
    //   meta: {
    //     authRoute: true,
    //   },
    //   props: {
    //     title: "Danh sách ví người dùng | 5 Phút Thuộc Bài - Đối tác Tâm Trí Lực",
    //     titleI18n: "Personnel:updateTab",
    //   },
    // },
    {
      url: ":id",
      component: "Page/CreateEdit",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Người dùng | 5 Phút Thuộc Bài - Đối tác Tâm Trí Lực",
        titleI18n: "Personnel:updateTab",
      },
    },
    {
      url: "",
      component: "Page",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Người dùng | 5 Phút Thuộc Bài - Đối tác Tâm Trí Lực",
        titleI18n: "Personnel:titleTab",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  roles: ["superadmin", "hr", "chief_accountant", "accountant"],
};
