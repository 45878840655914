import {
  options,
  options_category_select,
} from "@Modules/MstOptions/configs/constants";

import list_options from "./reducers";
import list_options_category_select from "./reducers/optionsSelect";

export default {
  [options]: list_options,
  [options_category_select]: list_options_category_select,
};
