export default [
  { key: "Accountant" },
  { key: "AccountantDetail" },
  { key: "AccountantUser" },
  { key: "Admin" },
  { key: "AdminHistoricDetail" },
  { key: "ConfigEvent" },
  { key: "ConfigPercent" },
  { key: "GroupCode" },
  { key: "ItemConfig" },
  { key: "Options" },
  { key: "MstOptions" },
  { key: "StaticPage" },
  { key: "Authenticate" },
  { key: "User" },
  { key: "PaymentDemand" },
  { key: "Dashboard" },
  { key: "SummaryHistoryUser" },
  { key: "TicketManagement" },
  { key: "ReportManagement" },
  { key: "InfomationManagement" },
  { key: "DateConfig" },
  { key: "CommisionTicketReply" },
  { key: "AdminHistoric" },
  { key: "MailTemplate" },
  { key: "ConfigNotification" },
  { key: "GroupTax" },
  { key: "ReportProccessing" },
  { key: "ReportSaleOrder" },
  { key: "ReportSalesOrder" },
  { key: "ReportSaleOrderAffiliate" },
  { key: "TermOfPayment" },
  { key: "Department" },
  { key: "ListUser" },
  { key: "Personnel" },
  { key: "Agency" },
  { key: "TransferWallet" },
  { key: "ReportPaymentOrder" },
  { key: "ReportCostPrice" },
  { key: "ReportCareOrder" },
  { key: "Revenue" },
  { key: "PercentCommision" },
  { key: "WalletManagerCashback" },
  { key: "ConfigCardAmount" },
  { key: "ConfigUserGroup" },
  { key: "ConfigUser" },
  { key: "GroupLevelUser" },
  { key: "TypeProductUser" },
  { key: "ProductUser" },
  { key: "SalesOrder" },
  { key: "Roles" },
  { key: "OTP" },
  { key: "NLCommissions" },
];
