import API from "../../configs/api";
import apiSTNHDMethod from "@utility/ApiSTNHDMethod";
import apiMethod from "@utility/ApiMethod";

import list_users from "@assets/json/users.json";
import list_categories from "@assets/json/categories.json";

export const getListUserSTNHĐ = () => {
  return Promise.resolve(list_users);
};

export const getListCategorySTNHĐ = () => {
  return Promise.resolve(list_categories);
};

export const getListCity = async (payload) => {
  try {
    const { data } = await apiSTNHDMethod.get(`${API.GET_COMMON_GET_CITY}`);
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getListDistrict = async (payload) => {
  try {
    const { data } = await apiSTNHDMethod.get(
      `${API.GET_COMMON_GET_DISTRICT}`,
      {
        params: payload,
      }
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const saveSettingColumn = async (
  payload = { userId: 0, key: "", arrSetting: "" }
) => {
  const { codeLanguage = "vi-VN", userId, key, arrSetting, ...rest } = payload;
  try {
    const { data } = await apiMethod.post(
      `${codeLanguage}${API.SAVE_SETTING_COLUMN}`,
      {
        userId: userId,
        reportName: key,
        columnsExport: arrSetting,
      }
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getSettingColumn = async (payload) => {
  const { codeLanguage = "vi-VN", userId, key, ...rest } = payload;
  try {
    const { data } = await apiMethod.get(
      `${"vi-VN"}${API.GET_SETTING_COLUMN}/${userId}`
    );
    return data;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getListGroupCode = (payload) => {
  const { groupCode, ...res } = payload;
  return apiMethod.get(`${API.OPTION_LIST_GROUPCODE}/${groupCode}`, res);
};