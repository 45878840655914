export const SET_ON_BOARD = "auth/SET_ON_BOARD";
export const SET_INFO_DEVICE = "common/SET_INFO_DEVICE";
export const SET_STATUS_MODAL_LOGOUT = "common/SET_STATUS_MODAL_LOGOUT";
export const GET_DISTRICT = "common/GET_DISTRICT";
export const GET_PROVICE = "common/GET_PROVICE";
export const ERROR = "common/ERROR";
export const SET_BASIC_DATA_SUCCESS = "common/GET_BASIC_DATA_SUCCESS";
export const SET_THEME = "common/SET_THEME";

export const SET_COLUMNS_TABLE_SETTING = "common/SET_COLUMNS_TABLE_SETTING";
export const SET_IS_OPEN_TABLE_SETTING = "common/SET_IS_OPEN_TABLE_SETTING";
