import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "ListUser",
  dir: "ListUser",
  pathRoot: "list-user",
  routes: [
    {
      url: "transfer-history/:id",
      component: "Page/HistoryUserTransfer.js",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Lịch sử giao dịch | 5 Phút Thuộc Bài - Đối tác Tâm Trí Lực",
        titleI18n: "ListUser:historyTab",
      },
    },

    {
      url: "change-balance/:id",
      component: "Page/HistoryChangeBalance.js",
      meta: {
        authRoute: true,
      },
      props: {
        title:
          "Lịch sử chỉnh sửa số dư | 5 Phút Thuộc Bài - Đối tác Tâm Trí Lực",
        titleI18n: "ListUser:historyTab",
      },
    },

    {
      url: "",
      component: "Page",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Người dùng | 5 Phút Thuộc Bài - Đối tác Tâm Trí Lực",
        titleI18n: "ListUser:titleTab",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  roles: ["superadmin", "hr", "chief_accountant", "accountant"],
};
