import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "PaymentHistory",
  dir: "PaymentHistory",
  pathRoot: "payment-history",
  routes: [
    {
      url: "",
      component: "Page",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Nhóm hoa hồng | 5 Phút Thuộc Bài - Đối tác Tâm Trí Lực",
        titleI18n: "PaymentHistory:titleTab",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  roles: ["superadmin", "view_admin", "edit_admin"],
};
