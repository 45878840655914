import { put, call, takeEvery, select } from "redux-saga/effects";
import * as Actions from "./constants";
import * as ActionsAuth from "../auth/actions";
import { signIn } from "./service";
import { RETCODE_SUCCESS, RETTEXT_SUCCESS } from "@configs/contants";
import apiMethod from "@utility/ApiMethod";
import { actions as ActionsUser } from "../../../../store/user/reducer";
/**
 * Fetch data saga
 * @returns {IterableIterator<*>}
 */
function* fetchSignInSaga({ payload }) {
  try {
    const res = yield call(signIn, payload);
    const { data } = res;
    if (res.retCode === RETCODE_SUCCESS && res.retText === RETTEXT_SUCCESS) {
      apiMethod.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${data.token}`;
      localStorage.setItem("roleCode", data?.role);
      yield put({ type: Actions.SIGN_IN_SUCCESS, payload: data });
      yield put(ActionsUser.setInfoData(data));
    } else {
      yield put({ type: Actions.SIGN_IN_ERROR, error: res.data.retText });
    }
  } catch (e) {
    yield put({ type: Actions.SIGN_IN_ERROR, error: e });
  }
}

function* fetchLogoutSaga() {
  yield put(ActionsAuth.authLogoutSuccess({}));
}

export default function* authSaga() {
  yield takeEvery(Actions.CALL_SIGN_IN_METHOD, fetchSignInSaga);
  yield takeEvery(Actions.CALL_LOGOUT_METHOD, fetchLogoutSaga);
}
