import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "ConfigEvent",
  dir: "ConfigEvent",
  pathRoot: "config-event",
  routes: [
    {
      url: "config",
      component: "Page/ConfigItem",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Thiết lập | 5 Phút Thuộc Bài",
        titleI18n: "ConfigEvent:config",
      },
    },
    {
      url: ":id",
      component: "Page/CreateEdit",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Câu hỏi| 5 Phút Thuộc Bài",
        titleI18n: "ConfigEvent:update",
      },
    },
    {
      url: "",
      component: "Page",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Câu hỏi | 5 Phút Thuộc Bài",
        titleI18n: "ConfigEvent:titleTab",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  roles: ["superadmin", "view_admin", "edit_admin"],
};
