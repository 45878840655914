import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import * as Actions from "./constants";

const initState = {
  onBoardScreen: true,
  infoDevice: {},
  statusModalLogout: false,
  province: [],
  district: [],
  theme: "light",
};

const CommonReducer = (state = initState, action = {}) => {
  switch (action.type) {
    case Actions.SET_ON_BOARD:
      return { ...state, onBoardScreen: false };
    case Actions.SET_INFO_DEVICE:
      return { ...state, infoDevice: { ...action.payload } };
    case Actions.SET_BASIC_DATA_SUCCESS:
      return { ...state, ...action };
    case Actions.SET_STATUS_MODAL_LOGOUT:
      return { ...state, statusModalLogout: action.payload };
    case Actions.SET_THEME:
      return { ...state, theme: action.payload };
    case Actions.SET_COLUMNS_TABLE_SETTING:
      return {
          ...state,
          columnsTableSetting: {
            ...state.columnsTableSetting,
            [action.payload.key]: action.payload.value
          }
        }
    case Actions.SET_IS_OPEN_TABLE_SETTING:
      return {
          ...state,
          columnsTableSetting: {
            ...state.columnsTableSetting,
            isOpen: action.payload
          }
        }
    default:
      return state;
  }
};

const persistConfig = {
  key: "common",
  storage,
};

export default persistReducer(persistConfig, CommonReducer);
