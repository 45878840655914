const namespace = "config_user";

export const config_user = `${namespace}_list`;
export const titleForIncomeActiveTooltip =
  "1. Áp dụng khi người dùng nạp tiền ở doanh thu ngoài hệ thống.\n2. Có 2 trạng thái bật và tắt.\n3. Trạng thái bật: bắt buộc nhập %.\n4. Trạng thái tắt: nhóm đối tượng không áp dụng hưởng loại hoa hồng này";
export const titleForIncomePassiveTooltip =
  "1. Áp dụng với 2 trường hợp theo từng công thức riêng\n2. Có 2 trường hợp là:\n  + Đơn hàng có mã giới thiệu\n  + Doanh thu ngoài hệ thống có người giới thiệu\n3. Có 2 trạng thái bật và tắt\n4. Trạng thái bật: Bắt buộc nhập % hoặc số tiền (dựa theo chọn loại doanh thu để tính)\n5. Mặc định check on đối với thiết lập sao của nhóm, bắt buộc phải nhập loại sao cho nhóm đối tượng ( dạng số)\n6. Trạng thái tắt: Nhóm đối tượng không áp dụng hưởng loại hoa hồng này";
export const titleForIncomeNolimitTooltip =
  "1. Áp dụng khi khách hàng mua đơn hàng từ lần thứ 2 trở đi\n2. Có 2 trạng thái bật và tắt\n3. Trạng thái bật: Bắt buộc nhập % hoặc số tiền (dựa theo chọn loại doanh thu để tính)\n4. Trạng thái tắt: Nhóm đối tượng không áp dụng hưởng loại hoa hồng này";
export const titleForIncomeNolimitTooltipOther =
  "1. Áp dụng khi người được giới thiệu mua đơn hàng và đã có người giới thiệu trước đó\n2. Có 2 trạng thái bật và tắt\n3. Trạng thái bật: Bắt buộc nhập % hoặc số tiền (dựa theo chọn loại doanh thu để tính)\n4. Trạng thái tắt: Nhóm đối tượng không áp dụng hưởng loại hoa hồng này";
export const titleForSalesNumberNolimit =
  "Tính từ đơn: Số thứ tự đơn hàng tính từ đơn đầu tiên để xác định đơn bắt đầu có thu nhập trọn đời";
export const titleForStableIncome =
  "Hoa hồng cố định: Là hoa hồng giới thiệu mà nhóm đối tượng được nhận khi người dùng mua hàng bằng mã giới thiệu của người dùng thuộc nhóm đối tượng trong đơn đầu tiên";
export const titleForIncomeStableTooltip =
  "1. Áp dụng đối với các đơn hàng dầu tiên ghi nhận hoa hồng ( bán gói)\n2. Có 2 loại: Cố định và cột mốc\n3. Bắt buộc chọn 1 trong 2 loại\n *Cố định:\n   1. Khi khách hàng mua đơn đầu tiên sẽ nhận được hoa hồng cố định\n   2. Hoa hồng cố định nhận được dựa theo cấu hình được thiết lập\n *Cột mốc\n   1. Khi khách hàng mua hàng sẽ có tích luỹ doanh thu, khách hàng sẽ được hưởng hoa hồng theo cấu hình thiết lập doanh thu của từng cột mốc\n   2. Có khoảng thời được tích luỹ doanh thu nhất định\n   3. Hiện tạo đang mặc định:\n    - Theo tháng\n    - Theo quý\n    - Theo năm\n    - Vĩnh viễn";
export const titleFoCommissionTooltip =
  "1. Áp dụng đối với khách hàng nạp tiền doanh thu ngoài hệ thống qua người giới thiệu thì người giới thiệu được nhận tiền này.\n2. Có 2 trạng thái bật và tắt.\n3. Trạng thái bật: bắt buộc nhập %.\n4. Trạng thái tắt: nhóm đối tượng không áp dụng hưởng loại hoa hồng này.";
export const formulaForPassiveIncomeTooltip =
  "** Công thức tính đơn lẻ cho thu nhập thụ động:\n Thu nhập thụ động = Số tiền thực trả * % số tiền doanh thu chia thụ động cho đơn lẻ\n ** Công thức:\n  Thu nhập thụ động =[ (số tiền nạp thẻ * % config số tiền doanh thu chia cho đối tác ) * % config Doanh thu thụ động 30% ] / cho tổng số sao của tất cả các nhóm đối tác sau đó nhân cho số cấp sao hiện tại của đối tác\n - Khi vào ví lấy số tiền trên trừ 10% thuế";

export const formulaForGroupMemberStarTooltip =
  "** Công thức tính  tổng sao:\n Điều kiện:\n  - Nhóm đối tượng: thu nhập thụ động ở trạng thái bật\n  - Tổng số sao của 1 nhóm = Số lượng user trong 1 nhóm * loại sao của nhóm  đối tượng\n * Tổng số sao của tất cả các nhóm = Số sao của một nhóm tính bằng số lượng người nhân số sao của nhóm đó";
