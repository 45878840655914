import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "AccountantUser",
  dir: "AccountantUser",
  pathRoot: "accountant-user",
  routes: [
    {
      url: "all",
      component: "Page/All",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Đối soát đơn hàng | 5 Phút Thuộc Bài",
        titleI18n: "AccountantUser:titleTabSale",
      },
    },
    {
      url: "register",
      component: "Page/Register",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Đối đăng ký tài khoản mới | 5 Phút Thuộc Bài",
        titleI18n: "AccountantUser:titleRegister",
      },
    },
    {
      url: ":userId/:infoUserCommisionAuditId",
      component: "Page",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Lịch sử giới thiệu người dùng | 5 Phút Thuộc Bài",
        titleI18n: "AccountantUser:titleTab",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  roles: [
    "superadmin",
    "view_admin",
    "edit_admin",
    "accountant",
    "chief_accountant",
  ],
};
