import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "TicketManagement",
  dir: "TicketManagement",
  pathRoot: "ticket-management",
  routes: [
    // {
    //   url: ":id",
    //   component: "Page/CreateEdit",
    //   meta: {
    //     authRoute: true,
    //   },
    //   props: {
    //     title: "Loại | 5 Phút Thuộc Bài - Đối tác",
    //     titleI18n: "Options:updateTab",
    //   },
    // },
    {
      url: "",
      component: "Page",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Loại | 5 Phút Thuộc Bài - Đối tác",
        titleI18n: "TicketManagement:titleTab",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  roles: ["superadmin", "view_admin", "edit_admin", "helpdesk"],
};
