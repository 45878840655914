import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "ReportSalesOrder",
  dir: "ReportSalesOrder",
  pathRoot: "report-sales-order",
  routes: [
    {
      url: "",
      component: "Page",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Bài học video | 5 Phút Thuộc Bài",
        titleI18n: "ReportSalesOrder:titleTab",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
};
