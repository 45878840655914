import {
  payment_demand,
  payment_demand_detail,
} from "@Modules/PaymentDemand/configs/constants";

import list_payment_demand from "./reducers";
import list_payment_detail from "./reducers/listDetailPaymentDemand";
export default {
  [payment_demand]: list_payment_demand,
  [payment_demand_detail]: list_payment_detail,
};
