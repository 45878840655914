import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initReducer from "./Store/initReducer";

export default {
  name: "Options",
  dir: "Options",
  pathRoot: "options",
  routes: [
    {
      url: "video",
      component: "Page/Video",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Thiết lập Video | 5 Phút Thuộc Bài - Đối tác",
        titleI18n: "Options:updateTabVideo",
      },
    },
    {
      url: "otp",
      component: "Page/Otp",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Thiết lập OTP | 5 Phút Thuộc Bài - Đối tác",
        titleI18n: "Options:updateTabOtp",
      },
    },
    {
      url: "payment",
      component: "Page/Payment",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Thiết lập phương thức thanh toán | 5 Phút Thuộc Bài - Đối tác",
        titleI18n: "Options:updateTabPayment",
      },
    },
    {
      url: ":id",
      component: "Page/CreateEdit",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Loại | 5 Phút Thuộc Bài - Đối tác",
        titleI18n: "Options:updateTab",
      },
    },
    {
      url: "",
      component: "Page",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Loại | 5 Phút Thuộc Bài - Đối tác",
        titleI18n: "Options:titleTab",
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: true,
  redux: initReducer,
  roles: ["superadmin", "view_admin", "edit_admin"],
};
