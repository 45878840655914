import * as Actions from "./constants";

export function setOnBoard(payload) {
  return {
    type: Actions.SET_ON_BOARD,
    payload,
  };
}

export function setTheme(payload) {
  return {
    type: Actions.SET_THEME,
    payload,
  };
}

export function setInfoDevice(payload) {
  return {
    type: Actions.SET_INFO_DEVICE,
    payload,
  };
}

export function setStatusModalLogout(payload) {
  return {
    type: Actions.SET_STATUS_MODAL_LOGOUT,
    payload,
  };
}

export function getProvince() {
  return {
    type: Actions.GET_PROVICE,
  };
}

export function getDistrict(payload) {
  return {
    type: Actions.GET_DISTRICT,
    payload,
  };
}


export function setColumnsTableSetting(payload) {
  return {
    type: Actions.SET_COLUMNS_TABLE_SETTING,
    payload,
  };
}
export function setIsOpenColumnsTableSetting(payload) {
  return {
    type: Actions.SET_IS_OPEN_TABLE_SETTING,
    payload,
  };
}
